import React from 'react'
import axios from 'axios';
import Styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import MEDIA from '../helpers/mediaTemplates'


let FormGate = Styled.div`
  display:block;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom:0;
  height: 30vh;
  background-color: #000000;
  //background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 80%);
  transition: transform 1.2s cubic-bezier(0.16, 1, 0.3, 1);

  z-index: 10;

  .row{ margin-top:15px; }

  ${MEDIA.PHONE`
    height: 50vh;
    background: #000000;
  `};

  &.is-hidden{
    transform: translate3d(0, 30vh, 0);
    ${MEDIA.PHONE`
    transform: translate3d(0, 50vh, 0);
    `};
  }

  p{
    color: #ffffff;
    font-size: 1.3rem;
    text-align: center;

    ${MEDIA.PHONE`
    font-size: 1.1rem;
    `};
  }

  h3{
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
    font-size:1.6rem;

    ${MEDIA.PHONE`
    font-size: 1rem;
    `};
  }

  form{
    margin: 15px 0;
    ${MEDIA.PHONE`
    margin: 5px 0;
    `};
  }

  input, button{

    font-size: 1.3rem;
    border-radius: 0px;
    height: 55px;
    ${MEDIA.PHONE`
    font-size: 1rem;
    height: 45px;
    `};

  }

  input{ 
    background: transparent;
    border: 1px #ffffff solid;
    border-right: 1px solid transparent;
    transition: background .3s ease;
    color: #555555;
    padding: 20px;
    line-height: 1.42857143;

    ${MEDIA.PHONE`
      border-right: 1px #ffffff solid;
      padding: 10px;
    `};

    &::placeholder { 
      color: #ffffff;
    }
    &:focus {
      background-color: #2e2e2e;
      color: #ffffff;
    }
  }
  em{
    color:#777777;
    line-height:1.2;
  }
  small{
    line-height:1.2!important;
  }

  button{
    background-color: #000000;
    color: #ffffff;
    text-transform: uppercase;
   
    display: inline-block;
    letter-spacing: 2px;
   
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-image: none;
    padding: 15px 20px;

    border: 1px #ccc solid;
   
    ${MEDIA.PHONE`
      width:100%;
      padding: 10px;
      border-color:transparent!important;
      background-color:#1a1a1a!important;
    
    `};

    &:hover{
      background-color: #1a1a1a;
      color: #ffffff;
    }


  }

  button:disabled,
  button[disabled]{
    color: #2e2e2e;
    ${MEDIA.PHONE`
    background-color:transparent!important;
    border-color:transparent!important;
    `};

    &:hover{
      color: #2e2e2e;
    }

  }

`
let FormComment = Styled.div`
  //background-color: #fefefe;
  padding: 0px;
  padding-top: 10px;
  //border-top: 1px #eeeeee solid;

  .CommentTitle{
    text-transform: uppercase;
  }
  textarea{
    background: transparent;
    border: 1px solid transparent;

    border-radius: 0;
    max-width: 500px;
    padding: 0;
    max-height:150px;
    min-height:40px;

    &:focus{
      background: transparent;
      color:#000000;
     
    }
  &::placeholder {
    font-style: italic;
    font-size:1rem;
  }
 
  


  button{

    color: #000000!important;
    border:none;
  }

  button:disabled,
  button[disabled]{
    color: #cccccc;
    background-color:transparent!important;
  }
`


let NarrowContainer = Styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding:10px;

  form{
    margin-bottom:0;
  }
  
  p{
    width: 100%;
  }
  button{
    width:100%;
  }

  ${MEDIA.PHONE`
  padding:20px;
  `};

`
let FormEnquiry = Styled.div`
  display: block;
`

class DataForm extends React.Component {
    constructor() {
      super();
      this.state = { email: "", name: "", message: "", isLoading: false, gateVisible: false, isSubmitted: false };
      this.commentInput = React.createRef();
    }

    componentDidMount = () =>{
      const $this = this;
      setTimeout(function(){
        $this.setState({ "gateVisible": true });
      }, 800);

      if(this.props.type == "comment"){
        $this.setState({ 'commentOpen': true, 'commentPrompt': this.getRandomInt(0, 4), commentPromptText: this.updateCommentText() });

      }

      if(this.props.type == "enquiry"){
        $this.setState({ 'enquiryOpen': false });
      }
     
    }
   
    handleChange = e => {
      if(e.target.name == "email"){
        if( this.testEmail(e.target.value) ) {

          this.setState({ [e.target.name]: e.target.value });
          this.props.setDataInfo( [e.target.name],e.target.value ); 

        }
      }
      else {

        this.setState({ [e.target.name]: e.target.value });
        this.props.setDataInfo( [e.target.name],e.target.value ); 

      }

    }

    handleCommentsChange = e =>{

      if(this.state.email == "") this.setState({'email': localStorage.getItem('gate') })


      this.setState({ [e.target.name]: e.target.value });
      this.setState({"artwork": this.props.artwork});
      this.setState({"commentPromptText": this.updateCommentText()});
    }




    handleEnquiryChange = e =>{

      if(this.state.email == "") this.setState({'email': localStorage.getItem('gate') })
      this.setState({ [e.target.name]: e.target.value });
      this.setState({"artwork": this.props.artwork});
    }



    testEmail = (email) =>{
       // don't remember from where i copied this code, but this works.
       let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
       if ( re.test(email) ) {
           // this is a valid email address
           // call setState({email: email}) to update the email
           // or update the data in redux store.
           return true;
       }
       else return false;

    }



    handleSubmit = event => {
      event.preventDefault();
      let $state = this.state;
      const $this = this;

     

      const formGate = 'https://getform.io/f/d9efd270-100a-4acf-b1fb-8ccd73bd3da9';
      const formComments = 'https://getform.io/f/850c57c7-9e47-45c3-b7ae-1e4a78eba0b0';
      const formEnquiries = 'https://getform.io/f/40f00aa8-628a-4a69-9d62-590378a20117';

      let formSubmissionUrl = formGate;

      if($this.props.type == "gate"){
        formSubmissionUrl = formGate;
      }
      else if($this.props.type == "comment"){
        formSubmissionUrl = formComments;
     
      }
      else if($this.props.type == "enquiry"){
        formSubmissionUrl = formEnquiries;
      }
      $this.setState({isLoading:true});
      axios
        .post(
          formSubmissionUrl,
          this.state,
          { headers: { Accept: "application/json" } }
        )
        .then(function(response) {

          if( $this.props.type == "gate"){
            if($state.email !== "") {
              localStorage.setItem('gate', $state.email );
              
              $this.setState({"gateVisible": false})
              $this.setState({isLoading:false});
              setTimeout(function(){
                $this.props.setDataInfo( 'gateSubmit','true' ); 

              }, 500)
              
            } 

          }
          else if( $this.props.type == "comment"){
            $this.setState({"message": ""});
            $this.setState({commentPrompt: 5});
            $this.setState({isLoading:false});
            $this.closeComment();
          }
          else if( $this.props.type == "enquiry"){
            $this.setState({"message": "", isSubmitted: true});
            $this.setState({isLoading:false});
          }

        })
        .catch(function(error) {
          console.log(error);
        });
    };
    
    toggleLoading = () =>{
      if(this.isLoading) return 'LOADING';
      else return '';
    }
    getFormGate = () => {
      const $this = this;
      let buttonText = (!$this.state.email) ? "Enter an email" : "Enter the show";

      let isLoadingButtonText = (!$this.state.isLoading) ? buttonText : (<span className="loading"></span>);

      let gateVisible = (this.state.gateVisible)  ? "is-visible" : "is-hidden";

      return (
        <FormGate className={ gateVisible }>
          <NarrowContainer>
           <h3>Please enter your info to access the show: </h3>
            <form onSubmit={this.handleSubmit}>
            <Row className="align-items-start no-gutters">
              <Col md={ 7 } className="">
              <div className="form-group">
               
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Your email"
                  name="email"
                  onChange={this.handleChange}
                  aria-autocomplete="off" 
                  autoComplete="false"
                  data-lpignore="true"
                />
              </div>
              </Col>
              <Col md={ 5 }>
              <div className="form-group">
              <button type="submit" className="btn" disabled={!$this.state.email}>{isLoadingButtonText}</button>
              </div>
              
              </Col>
            </Row>
            </form>
            <p><small><em>By entering your details you will get access to the interactive room and receive an occasional newsletter from the artists. Unsubscribe anytime.
         
              </em></small> </p>
            </NarrowContainer>
        </FormGate>
      );

    }

    openComment = () =>{
        this.setState({ 'commentOpen': true });
        this.changePromptComment();
    }

    closeComment = () =>{
      this.setState({ 'commentOpen': false });
    }
    updateCommentText = () =>{
      const commentPromptList = ['This reminds me of…', 'In this I see…', 'The other name of this could be…','It is interesting because…', 'I would describe the lines or colours as…','Comment sent. Thank you!'];
      
      let commentPromptTextValue = commentPromptList[this.state.commentPrompt];
      //this.setState({ commentPromptText: commentPromptTextValue });

      return commentPromptTextValue
    }
    changePromptComment = () => {
      if( this.state.commentPrompt < 4 ) this.setState({ commentPrompt: (this.state.commentPrompt+=1) });
      else this.setState({ commentPrompt: 0 });

      this.updateCommentText();
    }
    getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    openEnquiry = () =>{
      this.setState({ 'enquiryOpen': true });
    }

    closeEnquiry = () =>{
      this.setState({ 'enquiryOpen': false });
    }

    
    getFormComments = () => {

      const commentPromptList = ['This remind me of…', 'In this I see…', 'The other name of this could be…','It is interesting because…', 'I would describe the lines or colours as…','Comment sent. Thank you!'];
      let commentPrompt = this.updateCommentText();


      const colourList = ['#fae2e2', '#fbf7c4', '#e0eecb', '#cce6e7', '#ffe8fe'];
      const colourListButton = ['#d06b6b', '#9b7204', '#2e4d00', '#005356', '#873083'];

      let colourStyle = {
        backgroundColor: colourList[this.state.commentPrompt]
      }
      let colourButtonStyle = {
        backgroundColor: colourListButton[this.state.commentPrompt]
      }

      let submitBtn = (this.state.message.length < 3)? ( ''):( <button type="submit" className="btn">Send Comment</button>);


      let isLoadingButtonText = (!this.state.isLoading) ? submitBtn : (<span className="loading"></span>);


      let randomizeBtn = (this.state.message.length < 3)? ( <span className="randomize" onClick = {this.changePromptComment}></span>):('');

      let view = (!this.state.commentOpen) ? (<div><span onClick= {this.openComment}>{commentPrompt}</span></div>) 
      : (
        <form onSubmit={this.handleSubmit}>
        <div className="form-group">
        
        <textarea
          className="form-control"
          id="message"
          placeholder={commentPrompt}
          name="message"
          onChange={this.handleCommentsChange}
          data-gramm="false"
          required
          rows={2}
          cols={5}
          ref={ this.commentInput} 
          value={this.state.message}>
        
        </textarea>

        </div>
    

        <div className="form-group"> 
         { isLoadingButtonText }
        </div>



        </form>


      )

      return(
        <FormComment>
          <div className="CommentTitle"><small className="unselectable">Start a conversation {randomizeBtn}</small></div>
          {view}
        </FormComment>
      )
    }

    resetEnquiry = () => {
      this.setState({isSubmitted:false})
    }


    getFormEnquiries = () => {

    if(this.state.email == "") this.setState({'email': localStorage.getItem('gate') })
    let enquiryPromt = (typeof this.props.artwork !="undefined") ? (<span>Please fill the form below to enquire about <em>{this.props.artwork}</em> by {this.props.artist}.<br/></span>) : '' ;
    
    let isLoadingButtonText = (!this.state.isLoading) ? 'Enquire' : (<span className="loading"></span>);

    let enquiryForm = (!this.state.isSubmitted) ? (
      <div className="enquiryForm">
      <form onSubmit={this.handleSubmit}>
      {enquiryPromt} <br/>
      <div className="form-group">
          <label htmlFor="name" required="required">
            Your name
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Your name"
            name="name"
            onChange={this.handleEnquiryChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" required="required">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Your email"
            name="email"
            value={this.state.email}
            onChange={this.handleEnquiryChange}
            required = "true"
          />
        </div>

        <div className="form-group">
          <label htmlFor="message" required="required">
            Enquiry
          </label>
          
          <textarea
            className="form-control"
            id="message"
            placeholder="Your message"
            name="message"
            onChange={this.handleEnquiryChange}
            data-gramm="false"
            rows={2}
            cols={5}
            required = "true"
            ref={ this.commentInput} 
            value={this.state.message}>
          </textarea>
        </div>
    

        <div className="form-group"> 
         <button type="submit" className="btn">{isLoadingButtonText}</button> &nbsp;
         <span className="enquiryClose clickable" onClick = {this.closeEnquiry}><span>Close</span></span>
        </div>

      </form></div>) : (<div className="enquiryThanks"><span>Thank you.</span> <span className="clickable" onClick={this.resetEnquiry}>Back</span>.</div>);
    
    let view = (!this.state.enquiryOpen) ? ( <div className="enquiryBtnWrap"><span className="enquiryBtn btn" onClick = {this.openEnquiry}>Enquire</span></div>) : 
      ( <FormEnquiry>
          {enquiryForm}
        </FormEnquiry>
      )
      
      return (
        <div>
         {view}
        </div>
      );

    }
    
    render() {
      let showForm = this.getFormGate();

      if(this.props.type == "gate"){
        showForm = this.getFormGate();
      }
      else if(this.props.type == "comment"){
        showForm = this.getFormComments();
      }
      else if(this.props.type == "enquiry"){
        showForm = this.getFormEnquiries();
      }
      else showForm = '';

      return (
      <div>{ showForm }</div>

      );
    }
  }


  export default DataForm;
