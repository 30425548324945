import React, { Component } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styled from 'styled-components'

import { Row, Col, Container } from 'reactstrap'

import Img from 'gatsby-image';

import PropTypes from 'prop-types';
import { Link } from "gatsby";
import { graphql } from 'gatsby'

import IOFadeIn from '../components/io-fadein/io-fadein'


import DataForm from '../components/dataform' 

import MEDIA from '../helpers/mediaTemplates';

import ArtworkImage from '../components/artworkimage'

import ArtworkGrid from '../components/artworkgrid'

import { AnchorLink } from "gatsby-plugin-anchor-links";


let CoverIntro = Styled.div`
  position: fixed;
  width:100%;
  display:block;
  height:100%;
  top:0;
  left:0;
  height: 100vh;
  background: url(../images/title.svg) no-repeat left bottom;

  &>div{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }

  img{
    position: absolute;
    height:100%;
    width: auto;
    top:0;
    left:0;
  }
`

let CoverTitle= Styled.div`
  position: absolute;
  width: 100%;
  padding: 20px;

  color: #000;
  z-index:2;

  ${MEDIA.PHONE`
    padding: 5px;
  `};

  h1{
    margin-top:0;
  }
  p{
    margin-bottom:.5rem;
    font-size:.9rem;
  }

  .tiret{
    ${MEDIA.PHONE`
    height:5px;
  `};

  }

  &.coverCenter{
    text-align:center;
  }

  .date{
    text-transform: uppercase;
    margin: 20px 0;
    color: #9d411b;
    letter-spacing:1px;
    font-size: 1rem;
    ${MEDIA.PHONE`
    font-size: .8rem;
    `};
  }

  .coverTitle{
    text-align: center;
  }

  .center{
    text-align: center;
  }
  .coverSpacer{
    height: 30vh;
    display:block;
    witdth: 100%;
    ${MEDIA.PHONE`
      height: 50vh;
    `};
  }

  .coverText{
    max-width:620px;
    margin: 20px auto;
    text-align: justify;
  }
  .bold{
    font-weight:500;
  }

  .onlinewintershow{
    width: 400px;
    height: 50px;
    display: inline-block;
    ${MEDIA.PHONE`
    width:100%;
    display:block;
    height:30px;
    `};
  }
 
  ${MEDIA.PHONE`
    padding: 10px;
    padding-top: 20px;
  `};

`
let Spacer = Styled.div`
  display:block;
  width:100%;
  height:100vh;
  position:relative;
`

let Content = Styled.div`
  position:relative;
  z-index:3;
  width: 100%;
  background: #ffffff;
  color:#1a1a1a;

  .showInfo{
    margin-bottom: 60px;
    padding-top: 40px;
    line-height: 1.5;
    color: #020226!important;

    ${MEDIA.PHONE`
    padding-top:15px;
    `};

    h1{
      margin-top:40px;
    }
    .small{
      color: #020226;
    }
    .small &{
      p{
        margin-bottom:.5rem;
      }
    }
    p{
      margin-bottom:.5rem;
    }
    .date{
      margin-bottom:1rem;
    }
    
  }
`


let ShowSection = Styled.div`
  display:block;
  width:100%;
  
  position:relative;
  //color: #ffffff;
  margin: 0;
  padding: 120px 0;
  overflow:hidden;




  h1, h2, h3{
    color: #ffffff;
  }

  .small{
    font-size: .8rem;
    line-height: 1.6;
  }

  .rowAlternate{ 
    flex-direction: row-reverse;
    .col-md-7{
      text-align:center;
    }
  }


  .artwork-info {
    font-size: 1rem;
    line-height: 1.5;
    padding: 30px 0;
  }
  picture{
    display: inline-block;
    //height: 90vh;
    //width: auto;

  }
 
  &.white, &.whiteoff{
    background-color: #ffffff;
    color: #000;
  }
  &.whiteoff{
    //background-color: #f2f5f7;
    border-top: 1px #f2f5f7 solid;
    border-bottom: 1px #f2f5f7 solid;
  }

  .wall{
    width: 100%;
    height: 700px;
    text-align:center;
  }

 
  .col-md-7{
    text-align:center;
  }
  .col-md-5{
    text-align:center;
    padding-top: 10px;
  }

  .rowWallBg{
    background: transparent center center no-repeat;
    background-size:cover;  
    background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606413758/winter-show/bg/wall-listing-small_okzcpw.jpg');
  }


  .rowWallBgMedium{
    background: transparent center center no-repeat;
    background-size:cover;
    
    background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409549/winter-show/bg/wall-listing-medium-right_o80nye.jpg');
    
    &.left{
      background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409548/winter-show/bg/wall-listing-medium-left_dahpce.jpg');
    }
  }


  .rowWallBgLarge{
    background: transparent center center no-repeat;
    background-size:cover;
    
    background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409548/winter-show/bg/wall-listing-large-right_iszizo.jpg');
    
    /* &.left{
      background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409548/winter-show/bg/wall-listing-large-left_rbfjds.jpg');
    }
    */
  }



  .row img {
    width: auto;
    height: auto;

    vertical-align: top;
    max-height: 50vh;
    max-width: 50vh;

  }
  .center{
    text-align: center;
  }
`


let BackToTop = Styled.div`
  //text-align: center;
  padding: 150px 0;

`





let EnquireSection = Styled.div` 
  h3{ margin-top: 0!important;}
   //min-height:600px;
   background-color: #000000;
   color:#ffffff;
   position:relative;
   padding: 30px 0;

   ${MEDIA.PHONE`
   padding: 10px 0;
  `};

  .btn{
    background-color: #eeeeee;
    color: #000000;
    :hover{
      color: #000000;
    }
  }
  .enquiryBtnWrap{ 
    width:100%;
    text-align:right;
    ${MEDIA.PHONE`
    text-align:left;
    `};
  }
`
let ContentCover = Styled.div`
  position: relative;
  height:100%;
  width:100%;
`


const Show = ({data}) => {
  let item = data.strapiShow;


  const coverImage = (
    <div>
      <CoverTitle className="coverVisible">
        <div className="coverTitle">


          <div><p className="date">
            4 - 11 October 2021
            </p></div>

        </div>
    
        <div className="coverSpacer"></div>

      </CoverTitle>
      <CoverIntro><Img fluid={item.Large_cover ? item.Large_cover.childImageSharp.fluid : {}} alt={item.Title} /></CoverIntro>
    </div>
  );

  let showView = (item.Is_archived) ? (
  <Content>
    <CoverTitle className="coverVisible coverCenter">
    <br/>


        <p>Thank you for visiting Chromatic Terrain. The show has now ended.  
          <br/><br/><a href="/" className="btn">Get notified about next events</a></p>
      </CoverTitle>
      <CoverIntro><Img fluid={item.Large_cover ? item.Large_cover.childImageSharp.fluid : {}} alt={item.Title} /></CoverIntro>
    </Content>
    ):
  (
    <div>



    <Content id="show">
    

    <CoverIntro className="CoverChromaticTerrain"> 
      <ContentCover>
        <Container>
          <Row>
            <Col md={ 4 }>
              <div className="IntroLine">17 paintings are featured here. <br/>  They are work on paper of medium and smaller sizes. 
            All painted between January and September 2021. <br/> Please scroll below. </div>
            </Col>
            <Col md={ 8 }></Col>
          </Row>
        </Container>
      </ContentCover>
    </CoverIntro>
    <Spacer></Spacer>
    <div className="bganim"></div>
    <div className="small"><em>Click on images to enlarge</em></div>

    { item.Grid.GridItem.map((gridItem, i) => { 
      const alternateClass  = (i % 2 == 0 ) ? "rowAlternate" :"";
      const colourClass  = (i % 2 == 0 ) ? "white" :"whiteoff";
      const artworkPostion = (i % 2 == 0 ) ? "right" : "left";


      let artistName = '';

      if ( gridItem.artist != null) {

        artistName = gridItem.artist.Full_name;
      }

    return (

    <ShowSection id={ gridItem.gridId } className={ `section ${colourClass} ${gridItem.gridId}` } { ...gridItem } key={i}>
      <div>
      
     
        { gridItem.artworks.map((artwork, i) => {
          
          const artworkCloudUrl = artwork.Cloudinary_url;
          const buyPrintLink = (artwork.Print_url !=null) ? (<span><a href={artwork.Print_url} target="_blank"><em>Buy online </em> </a><br/></span>):(<span><em><a href={`mailto:as@artsime.com?subject=Enquiry: ${artwork.Title}`}>Enquire</a></em></span>); 
          const isSold = (artwork.Is_sold) ? (<span className="sold"></span>) : '';
          const ShowPrice =  (artwork.Is_sold) ? 'Original sold.' : `Original priced at £ ${artwork.Price}`;

  
         
          let getSize = artwork.Size;
          getSize = getSize.replace('cm','');
          getSize = getSize.replace(' ','');
          getSize = getSize.split("x");
      
          let imgWidth = getSize[0];
          let imgHeight = getSize[1];


          let imgScale = 2.5;
          let bgClass = 'rowWallBgMedium'
          let artworkSize = 'medium'

          if ( (imgWidth < 40 || imgHeight < 40) ) {
            imgScale = 6;
            bgClass = 'rowWallBg';
            artworkSize = 'small';
          } 
          else if ( imgWidth > 99 || imgHeight > 99 ) {
            bgClass = 'rowWallBgLarge';
            imgScale = 1.7;
            artworkSize = 'large'
          } 
          
          return (
            <div key={i}>
            <Container>
            <Row className={ `${alternateClass}`}>
              <Col md={ 7 }>
                  <div className={`${artworkPostion}`}>
                    <ArtworkImage bgClass = {bgClass} artist={artistName} imageWidth="100%" imageHeight="auto" imageUrl = { artworkCloudUrl} title={artwork.Title} id={artwork.id} description={artwork.Description} size={artwork.Size} medium={artwork.Medium} price={ShowPrice} edition={ artwork.Print_url } /> 
                    
                  </div>
                </Col>
               
             <Col md={ 5 }>
             <IOFadeIn>
                <div className="artwork-info">

                <p className="IntroLine">
                  {artistName}<br/> 
                  <em>{artwork.Title}</em><br/> 
                  {artwork.Size}<br/> 
                  {artwork.Medium}
                </p>
                <p className="small">
                {isSold} {ShowPrice}<br/> 
              {buyPrintLink}<br/> 
            

            </p>

                <p>
                <div className="small"
                  dangerouslySetInnerHTML={{
                    __html: artwork.Description,
                  }}
                />
                </p>
                 



            
            </div></IOFadeIn>
            </Col>


         </Row>
         </Container>
        
         </div>  
        )})}
 
   </div> 
    </ShowSection>
    )})}
   
   


    

    <EnquireSection>
      <Container>
        <Row>
          <Col md={ 7 }>  <h3>Enquire</h3> <p className="smaller"><em>For enquiries and press, please contact the artist.</em><br/>
            </p> </Col>
          <Col md={ 5 }></Col>
        </Row>
      </Container>
    </EnquireSection>
</Content>
</div>

  )




  return (
    <Layout showTitle = { item.Title } hideNav="true">
      <SEO title= { item.Title } />

      {showView}
    </Layout>
  )
} 

Show.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Show;


export const query = graphql`  
  query ShowQuery($slug: String) {
    strapiShow(slug: { eq: $slug }) {
      id
      Title
      slug
      Description
      Curators
      Start_date(formatString: "D/MM/Y")
      End_date(formatString: "D/MM/Y")

      Grid {
        GridItem {
          gridId
          artworks {
            Title
            Description
            id
            Image {
              url
            }
            Size
            Art_type
            Medium
            Price
            Cloudinary_url
            Print_url
            Is_sold
            Cloudinary_video
           
          }
          artist {
            Full_name
          }
        }
      }
      Poster {
        absolutePath
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
        }
      }
      Large_cover {
        absolutePath
        childImageSharp {
          fluid(maxHeight: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
        }
      }
      Is_archived
      artists {
        Full_name
        Short_bio
        Website
        instagram
        Slug
        Portrait {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

    }
  }
`