import styled from 'styled-components';


export const Container = styled.div`

  transition: .6s opacity cubic-bezier(0.16, 1, 0.3, 0.8), 1.2s transform cubic-bezier(0.16, 1, 0.3, 0.8);

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};


  transform: translateY( ${({ hasBeenVisible }) => (hasBeenVisible ? '-100px' : '0')});
  transform: translateY( ${({ isVisible }) => (isVisible ? '0' : '100px')});
 
  

//  background-color: ${({ hasBeenVisible }) => (hasBeenVisible ? 'blue' : 'red')});

`;
