import React from 'react';
import axios from 'axios';
import Styled from 'styled-components';


let Heart = Styled.div`
  display: block;
  height: 28px;
  width: 28px;
  background: no-repeat center center;
  background-size:contain;
  margin:5px 0;
`

class Like extends React.Component {
    constructor() {
      super();
      this.state = { email: "", id: "", title:"", actionType:"", isLiked:0};
    }

    componentDidMount = () =>{

      if(typeof this.props.id !="undefined") this.setState({id: this.props.id});
      if(typeof this.props.title !="undefined") this.setState({title: this.props.title});
      if(typeof this.props.artist !="undefined") this.setState({artist: this.props.artist});
      if(this.state.email == "") this.setState({'email': localStorage.getItem('gate') })

    
      if (localStorage.getItem("liketable") === null) {
        let likes = [];
        localStorage.setItem('liketable', JSON.stringify(likes) );
      }
      if (localStorage.getItem("actable") === null) {
        let act = [];
        localStorage.setItem('actable', JSON.stringify(act) );
      }
  

      let $this = this;

      setTimeout(function(){
        $this.checkIfLiked();
      },300);

    }

    getLikeTable = () => {
     if( localStorage.getItem("liketable") != null) return JSON.parse(localStorage.getItem("liketable"));
     else return [];
    }
    getActTable = () => {
      if( localStorage.getItem("actable") != null) return JSON.parse(localStorage.getItem("actable"));
      else return [];
    }

    checkIfLiked = () => {
    let liketable = this.getLikeTable();

        if( liketable.includes(this.state.id) ) {
          this.setState({'isLiked': 1});
          return true;
        }
        else {

          this.setState({'isLiked': 0});
          return false;
        }


    }

    updateLike = () => {
      const $this = this;

      let actable =  this.getActTable();
      this.setState({actable: JSON.stringify( actable ) });

      if( this.checkIfLiked() ){
        let liketable = this.getLikeTable();
        if(liketable != null ) {
          liketable = this.remove(liketable, this.state.id);
          this.setState({'isLiked': 0});
          localStorage.setItem('liketable', JSON.stringify( liketable ) );
          this.setState({liketable: JSON.stringify( liketable ) });
        //  this.setState({likedId: this.state.id });
          setTimeout(function(){ $this.sendLike(); },300);
        }
        
      }
      else{

        let liketable = this.getLikeTable();
        if(liketable != null ) {
          liketable.push( this.state.id );
          this.setState({'isLiked': 1});

          localStorage.setItem('liketable', JSON.stringify( liketable ) );
          this.setState({liketable: JSON.stringify( liketable ) });
         // this.setState({likedId: this.state.id });
          setTimeout(function(){ $this.sendLike(); },300);
       }
      }

      

    }

    remove = (arr, value) => { 
    
      return arr.filter(function(ele){ 
          return ele != value; 
      });
    }






    
    sendLike = () => {

      const $this = this;
      let $state = this.state;
     
      const likeform = 'https://getform.io/f/9290dfca-eaca-4eee-b82c-64641542d145';
   
      $this.setState({isLoading:true});
      axios
        .post(
          likeform,
          this.state,
          { headers: { Accept: "application/json" } }
        )
        .then(function(response) {

      

        })
        .catch(function(error) {
          console.log(error);
        });
    };
    

    
    render() {
     let liketable = this.getLikeTable();
     let likeClass = (liketable.includes(this.state.id))  ? 'liked' : 'like' ;

      return(
      <Heart className={likeClass} onClick={this.updateLike}/>

      )
      
    }
  }


  export default Like;
