import React, { Component } from 'react'
import Styled from 'styled-components'
import MEDIA from '../helpers/mediaTemplates';
import { Row, Col, Container, Dropdown } from 'reactstrap'
import ArtworkImage from '../components/artworkimage'
import Img from 'gatsby-image';
import IOFadeInSimple from '../components/io-fadeinsimple/io-fadeinsimple'
import { AnchorLink } from "gatsby-plugin-anchor-links";


let Wall = Styled.div`
  background:#000000;
  transition: background .3s ease;
  width: 100%;
  display: block;
  padding: 30px;

  &.showLiked{
    background-color:#eee;
  }
  &.showArtwork{
    background-color:#fff;
  }


  ${MEDIA.PHONE`
    padding: 15px 0px;

  `};


`


let LikedGrid = Styled.div`
  &.showArtwork{
    display:none;
  }
  &.showArtists{
    display: none;
  }
  min-height:300px;
`



let ArtistGrid = Styled.div`

.container{
  max-width: 960px;
}

  &.showArtists{
    display: none;
  }
  &.showLiked{
    display: none;
  }
  width: 100%;
  .rowAlternate{ 
    flex-direction: row-reverse;
    .col-md-7{
      //text-align:right;
    }
  }
  h2{ margin-top:0 }

  .row{
    margin-bottom: 50px;
  }

  .textContent{
    text-align: justify;
    max-width: 600px;
    margin:0 auto;
    line-height: 1.4;
  }

`



let ImageGrid = Styled.div`

  
  &.showArtwork{
    display:none;
  }
  &.showLiked{
    display: none;
  }
`

let ImageItem = Styled.div`
  display: inline-block;
  width: 10%;
  padding:5px;
  vertical-align: middle;

  ${MEDIA.PHONE`
  width: 33.3333%;
  `};

`

let Tabs = Styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: .8rem;
  color: #999;

  text-align: center;
  margin-bottom: 30px;


  .tab{
    cursor:pointer;
    transition: background-color .3s ease;
  }

  &.showArtwork{
    
    .tabArtists{
      font-weight: bold;
      color:#9d411b;
    }
  }
  &.showArtists{
    .tabArtworks{
      font-weight: bold;
      color:#9d411b;
    }
  }
  .heart{
    display: inline-block;
    height:20px;
    width: 20px;
    transform: translateY(2px);

  }

  .like{
    

  }
`




class ArtworkGrid extends Component {
  constructor(props) {
    super(props)
    this.state = { tab:1, susan:0, sue:0, simeon:0, justyna:0, elise:0, sandra:0, felicity:0 };

  }
  componentDidMount = () =>{
    if(typeof this.props.grid!="undefined") this.setState({grid: this.props.grid});
  
  }

  showArtworks = () =>{
    this.setState({tab:1});
  }

  showArtistsBio = () =>{
    this.setState({tab:2});
  }
  showLiked = () =>{
    this.setState({tab:3});
  }


  toggleArtistBio = (artistName) => {

    if(artistName === "susan-clare" ){
      if(this.state.susan == 0 ) this.setState({susan: 1});
      else this.setState({susan: 0});
    }
   

    //if(this.state.)
    //this.setState({[artistName]: 0 });

  }



  listLiked = () => {
    if (localStorage.getItem("liketable") === null) {
      let likes = [];
      localStorage.setItem('liketable', JSON.stringify(likes) );
    }
    let liketable = JSON.parse(localStorage.getItem("liketable"));

    return this.props.grid.GridItem.map((gridItem, i) => { 

      let artistName = '';

      if ( gridItem.artist != null) {
        artistName = gridItem.artist.Full_name;
      }
      return gridItem.artworks.map((artwork, i) => {

        if( liketable.includes(artwork.id) ) {
              
          const artworkCloudUrl = artwork.Cloudinary_url;
          const artworkCloudVideo = artwork.Cloudinary_video;
          const buyPrintLink = (artwork.Print_url !=null) ? (<span><a href={artwork.Print_url} target="_blank"><em>{artwork.Title} - Edition of 10, each available for £ 100</em> </a><br/></span>):(''); 
          const isSold = (artwork.Is_sold) ? (<span className="sold"></span>) : '';
          const ShowPrice =  (artwork.Is_sold) ? 'Original sold.' : `Original priced at £ ${artwork.Price}`;

          let getSize = artwork.Size;
          getSize = getSize.replace('cm','');
          getSize = getSize.replace(' ','');
          getSize = getSize.split("x");
      
          let imgWidth = getSize[0];
          let imgHeight = getSize[1];


          let imgScale = 2.8;
          let bgClass = 'rowWallBgMedium'
          let artworkSize = 'medium'

          if ( (imgWidth < 40 || imgHeight < 40)  ) {
            imgScale = 6;
            bgClass = 'rowWallBg';
            artworkSize = 'small';
          } else if ( imgWidth > 99 || imgHeight > 99 ) {
            bgClass = 'rowWallBgLarge';
            imgScale = 1.8;
            artworkSize = 'large'
          } 
          

          return (
            <ImageItem key={i}>
              <ArtworkImage  bgClass = {bgClass}  artist={artistName} imageScale={ imgScale } imageWidth={ imgWidth } imageHeight={ imgHeight } imageUrl = { artworkCloudUrl} title={artwork.Title} id={artwork.id} description={artwork.Description} size={artwork.Size} medium={artwork.Medium} price={ShowPrice} edition={ artwork.Print_url } /> {isSold} 
            </ImageItem>
          )
        } else return '';
            
        })
     
      

    })


  }

 
  render(){
    
      let artistGrid = this.props.artistsData.map((artist, i) => {
      const alternateClass  = (i % 2 == 0 ) ? "rowAlternate" :"";

      return ( 
      
      <Row key={i} className={`align-items-center row ${ alternateClass }`}>
        <Col md={ 4 }>
          <IOFadeInSimple><Img fluid={artist.Portrait ? artist.Portrait.childImageSharp.fluid : {}} alt={ artist.Full_name } /></IOFadeInSimple>
         </Col>
        <Col md={ 8 }>
        <IOFadeInSimple>
          <div className="textContent">
          
            <h2>{ artist.Full_name }</h2>

            <div className="small"
                dangerouslySetInnerHTML={{
                  __html: artist.Short_bio,
                }}
              />

             {/*  <span onClick = {()=>this.toggleArtistBio(artist.Slug)}></span> */} <br/>

         
            <p className="small"><a href={ artist.Website } target="_blank">{ artist.Full_name } Website</a></p>
        
            <AnchorLink to={`/winter-show#${artist.Slug}`} title="View artowrk">
                 <span className="btn light">View artworks</span>
              </AnchorLink>
              </div>
            </IOFadeInSimple>
        </Col>
        
        </Row>);
    });



      let artworkGrid = this.props.grid.GridItem.map((gridItem, i) => { 

      let artistName = '';

      if ( gridItem.artist != null) {
        //console.log('gridItem.artist', gridItem.artist.Full_name);
        artistName = gridItem.artist.Full_name;
      }
      return gridItem.artworks.map((artwork, i) => {
              
        const artworkCloudUrl = artwork.Cloudinary_url;
        const artworkCloudVideo = artwork.Cloudinary_video;
        const buyPrintLink = (artwork.Print_url !=null) ? (<span><a href={artwork.Print_url} target="_blank"><em>{artwork.Title} - Edition of 10, each available for £ 100</em> </a><br/></span>):(''); 
        const isSold = (artwork.Is_sold) ? (<span className="sold"></span>) : '';
        const ShowPrice =  (artwork.Is_sold) ? 'Original sold.' : `Original priced at £ ${artwork.Price}`;

        let getSize = artwork.Size;
        getSize = getSize.replace('cm','');
        getSize = getSize.replace(' ','');
        getSize = getSize.split("x");
    
        let imgWidth = getSize[0];
        let imgHeight = getSize[1];


        let imgScale = 2.8;
        let bgClass = 'rowWallBgMedium'
        let artworkSize = 'medium'

        if ( (imgWidth < 40 || imgHeight < 40)  ) {
          imgScale = 6;
          bgClass = 'rowWallBg';
          artworkSize = 'small';
        } else if ( imgWidth > 99 || imgHeight > 99 ) {
          bgClass = 'rowWallBgLarge';
          imgScale = 1.8;
          artworkSize = 'large'
        } 
        

        return (
          <ImageItem key={i}>
            <ArtworkImage isOnGrid={0} bgClass={bgClass} artist={artistName} imageScale={ imgScale } imageWidth={ imgWidth } imageHeight={ imgHeight } imageUrl = { artworkCloudUrl} title={artwork.Title} id={artwork.id} description={artwork.Description} size={artwork.Size} medium={artwork.Medium} price={ShowPrice} edition={ artwork.Print_url } /> {isSold} 
          </ImageItem>
        )
            
        })
     
      

    })

    let ShowClass = '';
    let likedView = '';
    if (this.state.tab === 1) ShowClass = 'showArtists';
    else if (this.state.tab === 2) ShowClass = 'showArtwork';
    else if (this.state.tab === 3){
      ShowClass = 'showLiked';
      likedView = this.listLiked();
    } 

    var likeClass = ( this.state.tab === 3) ? 'liked' : 'like';

    let artistContainer = (this.state.tab === 2) ? 'container' : '';



    return (
    <Wall className = {ShowClass}>
    <Container className={artistContainer} > 
      <Tabs className = {ShowClass}>
        <span onClick = { this.showArtworks} className="tab tabArtworks">Show artworks</span> &nbsp;&nbsp;
        <span onClick = { this.showArtistsBio } className="tab tabArtists">Show Artists</span> &nbsp;&nbsp;
        <span onClick = { this.showLiked } className={`tab heart ${likeClass}`}></span>
      </Tabs>
      
      <ArtistGrid className = {ShowClass}>{ artistGrid }</ArtistGrid>
      <ImageGrid className = {ShowClass}>{ artworkGrid }</ImageGrid>
    <LikedGrid className = {ShowClass}> {likedView} </LikedGrid>
    
    </Container></Wall> )


  }
 
}
export default ArtworkGrid;