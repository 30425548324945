import React from 'react';
import PropTypes from 'prop-types';
import IO from './io/io'
import { Container } from './io-fadeinsimple.css';

// <IO> uses a render prop to pass down `isVisible` and `hasBeenVisible`.
// In this example, we only care about `isVisible` and reset the styles
// every time we scroll back up. Use `hasBeenVisible` to keep the styles
// after scrolling back up and down again.
const IOFadeInSimple = ({ children }) => (
  <IO rootMargin="-80px">
    {({ isVisible, hasBeenVisible }) => (
      <Container isVisible={isVisible} hasBeenVisible={hasBeenVisible}>
        { children }
      </Container>
    )}
  </IO>
);

IOFadeInSimple.propTypes = {
  children: PropTypes.node.isRequired,
};


export default IOFadeInSimple;
