import React from 'react'
import Styled from 'styled-components'
import { Row, Col, Container } from 'reactstrap'
import IOFadeIn from '../components/io-fadein/io-fadein'
import MEDIA from '../helpers/mediaTemplates';
import DataForm from '../components/dataform';
import Like from '../components/like';
import axios from 'axios';

let FullScreenImg = Styled.div`
  position: fixed;
  top:0;
  left:0;
  width: 100%!important;
  height: 100%!important;


  ${MEDIA.PHONE`
  overflow: scroll;

  `};

  background: no-repeat center center #ffffff;
 
  z-index:30;
  display:block;
  height:100vh;

  transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);


  .close{
    width:40px;
    height:40px;
    display:block;
    position: absolute;
    right:0;
    top:0;
    cursor: pointer;
    z-index: inherit;

    ${MEDIA.PHONE`
    top:50px;
  
    `};
  
  }

  em{
    color:#dddddd;
  }

  

  .row & {
    img{max-height: 100vh!important;}
    
  }

  .row{
    margin-top: 0;
  }

 


  picture{

    max-height:100%;
    text-align:center;
    //height: 100vh;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block

    img{
      max-height: 100vh!important;
    }
  }

 

  .artwork-info {
    font-size: 1rem;
    line-height: 1.5;
    padding: 30px 0;
    //max-width:90%;

    ${MEDIA.PHONE`
    padding: 0 0;
  
    `};
   
    
  }
  .CommentTitle{
    color: #000000;
  }

  .dark{
    color: #000000;
  }


  .sidebar{

    width:100%;
    height:100vh;
    background-color: #1a1a1a;
    color: #ffffff;
    text-align:left;
    .sideContent{
      max-width:350px;
      padding-left:5%;
    }
  }
  .imageContent{
    background: no-repeat center center #000000;
    align-self: center;
    ${MEDIA.PHONE`
      margin-top:45px;
      //max-height: 350px;

   `};
  }
  .enquiryForm{
    position:absolute;
    width:100%;
    bottom:0;
    background-color: #000000;
    //background-color: #1a1a1a;
    //height:100vh;

    form{

      max-width:350px;
      padding:20px 0 20px;
      padding-left:5%;
    }
    .form-group{
      margin-bottom:1rem;
    }
  }
  .enquiryThanks{
    position:absolute;
    width:100%;
    bottom:0;
    background:#000000;

  }
  .enquiryBtn{
    position:absolute;
    width:100%;
    bottom:0;
    text-align:center;
    padding: 10px 0;
  }

  .enquiryClose{
    position:absolute;
    right:0;
    top:0;
    width:40px;
    height:40px;
    display:block;
    position: absolute;
  }


  .wall{
    width: 100%;
    height: 100vh!important;
    text-align:center;
    background: transparent center center no-repeat;
    background-size:cover;
    background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409549/winter-show/bg/wall-listing-medium-right_o80nye.jpg');
    
    padding-top:20px;
    ${MEDIA.PHONE`
      background-image:none!important;
      background-color:#ffffff;
    `};

  }

  .rowWallBg{
    background: transparent center center no-repeat;
    background-size:cover;
    
    background-image: url('https://res.cloudinary.com/ashow/image/upload/v1606413758/c_limit,q_auto,f_auto/winter-show/bg/wall-listing-small_okzcpw.jpg');
  }


  .rowWallBgMedium{
    background: transparent center center no-repeat;
    background-size:cover;
    
    background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409549/winter-show/bg/wall-listing-medium-right_o80nye.jpg');
    

  }


  .rowWallBgLarge{
    background: transparent center center no-repeat;
    background-size:cover;
    
    background-image: url('https://res.cloudinary.com/ashow/image/upload/c_limit,q_auto,f_auto/v1606409548/winter-show/bg/wall-listing-large-right_iszizo.jpg');
    
    */
  }

  .imgWrapper{
    display:inline-block;
  }
`

let ZoomImg = Styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat center center #ffffff;
  background-size: contain;
  background-origin: content-box, padding-box;
  top:0;
  left:0;
  z-index:10;
  padding: 70px;

  ${MEDIA.PHONE`
  padding: 40px;
`};

`


let ArtworkImg = Styled.div`


  .artworkImage{
      transition: height .4s ease;
      transition: width .4s ease;
      max-height:300px;
      
  }
`
let ArtworkLarge= Styled.div`
 
`

class ArtworkImage extends React.Component {
  constructor() {
    super();
    this.state = { isZoomed: false, onWall:true, isOnGrid:0 };
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount(){
    //this.setState({isVisible: false})
    if(typeof this.props.title !="undefined") this.setState({title: this.props.title});
    if(typeof this.props.description !="undefined") this.setState({description: this.props.description});
    if(typeof this.props.medium !="undefined") this.setState({medium: this.props.medium});
    if(typeof this.props.price !="undefined") this.setState({price: this.props.price});
    if(typeof this.props.edition !="undefined") this.setState({edition: this.props.edition});
    if(typeof this.props.size !="undefined") this.setState({size: this.props.size});
    if(typeof this.props.imageUrl !="undefined") this.setState({imageUrl: this.props.imageUrl});
    if(typeof this.props.id !="undefined") this.setState({id: this.props.id});
    if(typeof this.props.artist !="undefined") this.setState({artist: this.props.artist});

    if(typeof this.props.imageWidth !="undefined") this.setState({imageWidth: this.props.imageWidth});
    if(typeof this.props.imageHeight !="undefined") this.setState({imageHeight: this.props.imageHeight});
    if(typeof this.props.imageScale !="undefined") this.setState({imageScale: this.props.imageScale});

    if(typeof this.props.bgClass != "undefined") this.setState({bgClass: this.props.bgClass});
    if(typeof this.props.isOnGrid != "undefined") this.setState({isOnGrid: this.props.isOnGrid});


    if (localStorage.getItem("actable") === null) {
      let act = [];
      localStorage.setItem('actable', JSON.stringify(act) );
    }

    document.addEventListener("keydown", this.escFunction, false);
    
  } 

  getActTable = () => {
    if( localStorage.getItem("actable") != null) return JSON.parse(localStorage.getItem("actable"));
    else return [];
  }
  
  escFunction = (event) =>{
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
      if(this.state.isZoomed){
        //console.log('>>>HEY');
        this.toggleZoom();
        document.documentElement.style.overflow = 'auto';
        this.updateAct();
      }
      
    }
  }


  updateAct = () => {
    const $this = this;
    let action = (!this.state.isZoomed) ? 'o' : 'c';


    let actable = this.getActTable();
    actable.push( action + ':' + this.state.id );
    localStorage.setItem('actable', JSON.stringify( actable ) );

    this.setState({action: action+'-' + this.state.id });
    this.setState({ actable: JSON.stringify(actable) });

    setTimeout(function(){ $this.sendAct() }, 100);
  }

    
  sendAct = () => {

    const $this = this;
    let $state = this.state;
    let $email = "-"
    if (localStorage.getItem("gate") != null) {
      $email = localStorage.getItem("gate");
    }

    let myState = {
      id: this.state.id,
      title: this.state.title,
      action: this.state.action,
      actable: this.state.actable,
      email: $email
    }
   
    const actform = 'https://getform.io/f/13548e74-417c-4145-954f-84e17c3e21a7';
 
    $this.setState({isLoading:true});
    axios
      .post(
        actform,
        myState,
        { headers: { Accept: "application/json" } }
      )
      .then(function(response) {

    

      })
      .catch(function(error) {
        console.log(error);
      });
  };
  

 

  toggleZoom = () =>{
     this.setState({isZoomed: ( (this.state.isZoomed) ? 0 : 1 ) });

     if( !this.state.isZoomed ) document.documentElement.style.overflow = 'hidden';
     else document.documentElement.style.overflow = 'auto';

    this.updateAct();
  }


  zoomPreview = () =>{ this.setState({onWall:0}); }
  resetPreview = () =>{ this.setState({onWall:1}); }


  render(){
    const ImageUrl = this.state.imageUrl;

    let smallHeight = this.state.imageHeight * (this.state.imageScale);
    let imgStyle = {
      width: this.state.imageWidth * this.state.imageScale + 'px',
      height: smallHeight + 'px',
      marginTop: (0 - (ImageUrl / 2) ) + 'px'
    }

    let wallUsableSpace = 60;
    if(this.state.bgClass === 'rowWallBgMedium') { 
      wallUsableSpace = 50;
    }
    else if(this.state.bgClass === 'rowWallBg') { 
      wallUsableSpace = 75;
    }

    else if(this.state.bgClass === 'rowWallBgLarge') { 
      wallUsableSpace = 65;
    }

    let largeHeight = this.state.imageHeight * (this.state.imageScale+1);

    let wallSpace =  0;

    //(200 - (largeHeight/2) )

    let imgStyleLarge = {
      width: this.state.imageWidth * (this.state.imageScale+1) + 'px',
      height: largeHeight + 'px',
      marginTop: wallSpace + 'px'
    }



    let bgStyle = {
      //backgroundImage: `url(https://res.cloudinary.com/ashow/image/upload/c_limit,w_10,q_auto,f_auto/v1602527722/artworks/${ImageUrl }.jpg)`,
      opacity: (this.state.isZoomed) ? 1 : 0
    }

    let bg = `https://res.cloudinary.com/ashow/image/upload/c_limit,w_1200,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl}`
    let backgroundImg = {
      backgroundImage: `url(${bg})`
    }
    let  ZoomedView = (<ZoomImg style={backgroundImg}></ZoomImg>) ;

    let fullScreenImage = (
      <picture onClick={this.toggleZoom}>
        <source media="(max-width: 350px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_350,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
        <source media="(max-width: 600px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_600,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
        <source media="(max-width: 900px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
        <source media="(max-width: 1200px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_1200,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
        <img src={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_1600,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}  alt={this.state.title} />
     </picture>
    );


    let bgClass = this.state.bgClass;
    let view = (this.state.isZoomed) ? (
    
      

    <FullScreenImg style={bgStyle}>

      <div className="close" onClick={this.toggleZoom} ></div>
      <Row className="no-gutters">
        <Col md={ 8 } className={`imageContent`} onMouseEnter={ this.zoomPreview } onMouseLeave={ this.resetPreview }>

        { ZoomedView }
        
        <div className={`wall ${bgClass}`}>

          <div style={imgStyleLarge}  className="imgWrapper">
          <ArtworkImg>
            <picture className="artworkImage" >
                <source media="(max-width: 350px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_350,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
                <source media="(max-width: 600px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_600,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
                <source media="(max-width: 900px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
                <source media="(max-width: 1200px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_1200,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
                <img src={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl}`}  alt={this.state.title} />
            </picture>
          </ArtworkImg>
          </div>
        
        </div>
        </Col>  


        <Col md={ 4 } className={` d-md-block d-lg-block sidebar`}>
          <div className="artwork-info">
            
          
            <p className="small sideContent">
              <strong>{this.state.artist}</strong><br/> 
              <em>{this.state.title}</em><br/> 
              {this.state.size}<br/> 
              {this.state.medium}
            </p>

            <p>
            <div className="small sideContent"
              dangerouslySetInnerHTML={{
                __html: this.state.description,
              }}
            />
            </p>
            
            <p className="small sideContent">
              {this.state.price}
             
            </p>


            <div className="sideContent dark">
            <span className="small"><em><a href={`mailto:as@artsime.com?subject=Enquiry: ${this.state.title}`}>Enquire</a></em></span>
        
            </div>
            <br/><br/>
            
           
            
          </div>

        </Col>
      </Row>
       
    </FullScreenImg> ):('');    


    let artworkView = ( this.state.onWall ) ? (
      <ArtworkImg>
        <picture className="artworkImage" onClick = {this.toggleZoom} style={imgStyle}>
            <source media="(max-width: 350px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_350,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <source media="(max-width: 600px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_600,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <source media="(max-width: 900px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <source media="(max-width: 1200px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_1200,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <img src={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}  alt={this.state.title} />
        </picture>
      </ArtworkImg>
    ) : (
    <ArtworkLarge>
        <picture className="artworkImage" onClick = {this.toggleZoom}>
            <source media="(max-width: 350px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_350,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <source media="(max-width: 600px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_600,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <source media="(max-width: 900px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <source media="(max-width: 1200px)" srcSet={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_1200,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}/>
            <img src={`https://res.cloudinary.com/ashow/image/upload/c_limit,w_900,q_auto,f_auto/v1602527722/chromatic-terrain/${ImageUrl }`}  alt={this.state.title} />
        </picture>
      </ArtworkLarge>
    );

   

    return(
    <div>
      {view} 

      
      
      <div>{artworkView}</div>

    
    
      
      </div>
    
    )
  }
}


export default ArtworkImage ;